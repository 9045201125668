// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// import { HashLink as LinkHash } from 'react-router-hash-link';

require('./styles.scss');

/*
let intro = require('./cover-desktop.mp4')
let introMobile = require('./cover-mobile.mp4')
let introWebM = require('./cover-desktop.webm')
let introWebMmobile = require('./cover-mobile.webm')
*/
let poster = require('./cover.jpg');
let posterMobile = require('./cover-mobile.jpg');

export function cover(props: { title: string, subtitle: string }) {

	const isMobile = window.innerWidth < 600
//   const isIOS9 = /iPhone OS [6789]_/.test(navigator.userAgent)


	return (
		<div className="cover">

			<img
				src={posterMobile}
				alt="Behandlung Physiotherapie und Craniosacral Therapie Winterthur"
				className="fallback portrait"
			/>
			<img src={poster} alt="Behandlung Physiotherapie und Craniosacral Therapie Winterthur" className="fallback landscape" />
			<div className="color-overlay" />

			<div>
				<div
					style={{
						position: 'absolute',
						top: '4em',
						left: 0,
						width: '100vw'
					}}
				/>
			</div>

			<div className="top gradient" />
			{ isMobile && 
			<>
			<div
				style={{
					position: 'absolute',
					bottom: '0',
					left: 0,
					width: '100vw',
					height: '100%'
				}}
			>
				<div className="ui container content">
					<div className="ui grid stackable hinweis">
						<div className="five wide column">
							{/* <LinkHash to="/kontakt#covidNote"> */}
								<img
									src={require('../imgs/hinweis-krankenkassen-anerkannt-emr-zertifiziert.png')}
									className="ui image fluid hinweis"
									alt="Hinweis Krankenkassen anerkannt und EMR zertifiziert"
								/>
							{/* </LinkHash> */}
						</div>
					</div>

					<div className="header container">
						<h1
							className="ui left aligned header"
							style={{
								textAlign: 'left'
							}}
						>
							<div className="sub header">{props.subtitle}</div>
							{props.title}
						</h1>
						<Link to="/angebot" replace>
						<div className="ui animated button" tabIndex="0">
							<div className="visible content">Zum Angebot</div>
							<div className="hidden content">
								<i className="right arrow icon" />
							</div>
						</div>
					</Link>
					</div>

				</div>
			</div>
			</>
		}
			
		{!isMobile &&
			<>
			<div
				style={{
					position: 'absolute',
					bottom: '0',
					left: 0,
					width: '100vw',
					height: '100%',
					minHeight: '666px'
				}}
			>
				<div className="ui container content">
					<div className="ui grid stackable hinweis">
						<div className="eleven wide column" />
						<div className="five wide column">
							{/* <LinkHash to="/kontakt#covidNote"> */}
								<img
									src={require('../imgs/hinweis-krankenkassen-anerkannt-emr-zertifiziert.svg')}
									className="ui image fluid hinweis"
									alt="Hinweis Krankenkassen anerkannt und EMR zertifiziert"
								/>
							{/* </LinkHash> */}
						</div>
					</div>

					<div className="header container">
						<h1 className="header" style={{}}>
							<div className="sub header">{props.subtitle}</div>
							{props.title}
						</h1>
						<Link to="/angebot" replace>
							<div className="ui animated button" tabIndex="0">
								<div className="visible content">Zum Angebot</div>
								<div className="hidden content">
									<i className="right arrow icon" />
								</div>
							</div>
						</Link>
					</div>
			
				</div>
			</div>
			<div className="captionWrapper" />
			</>
			}
		</div>
		
	);
}

export default cover;
